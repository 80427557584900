import _IconSettings from "@arco-design/web-react/icon/react-icon/IconSettings";
import _IconUserAdd from "@arco-design/web-react/icon/react-icon/IconUserAdd";
import _IconUserGroup from "@arco-design/web-react/icon/react-icon/IconUserGroup";
import _IconHome from "@arco-design/web-react/icon/react-icon/IconHome";
import _IconDashboard from "@arco-design/web-react/icon/react-icon/IconDashboard";
import auth from '@/utils/authentication';
import { useEffect, useMemo, useState } from 'react';
export const routes = [{
  title: '仪表盘',
  name: 'dashboard',
  key: 'dashboard',
  icon: _IconDashboard,
  children: [{
    title: '安心付',
    name: 'dashboard.list',
    key: 'dashboard/workplace'
  }, {
    title: '监控数据',
    name: 'dashboard.merchant',
    key: 'dashboard/merchant'
  }, {
    title: '门店监控数据',
    name: 'dashboard.merchant.shop',
    key: 'dashboard/shop',
    ignore: true
  }, {
    title: '资金管理',
    name: 'dashboard.fund',
    key: 'dashboard/fund'
  }, {
    title: '资金明细',
    name: 'dashboard.fundLogs',
    key: 'dashboard/fundLogs'
  }, {
    title: '门店订单列表',
    name: 'dashboard.merchant.shop.order',
    key: 'dashboard/order',
    ignore: true
  }]
}, {
  title: '商家管理',
  name: 'shop',
  key: 'shop',
  icon: _IconHome,
  children: [{
    title: '商家列表',
    name: 'shop.list',
    key: 'shop/list'
  }, {
    title: '管理员',
    name: 'shop.admin',
    key: 'shop/admin',
    ignore: true
  }, {
    title: '安心付',
    name: 'shop.anxinPay',
    key: 'shop/anxinPay',
    ignore: true
  }, {
    title: '门店管理',
    name: 'shop.store',
    key: 'shop/store',
    ignore: true,
    children: [{
      title: '门店列表',
      name: 'shop.store.list',
      key: 'shop/store/index'
    }, {
      title: '门店编辑',
      name: 'shop.store.form',
      key: 'shop/store/form'
    }, {
      title: '员工管理',
      name: 'shop.store.staff',
      key: 'shop/store/staff'
    }, {
      title: '蚂蚁门店',
      name: 'shop.store.alipay',
      key: 'shop/store/alipay'
    }, {
      title: '安心付',
      name: 'shop.store.anxinPay',
      key: 'shop/store/anxinPay'
    }]
  }, {
    title: '分账信息',
    name: 'shop.commission',
    key: 'shop/commission',
    ignore: true
  }, {
    title: '个体户升级',
    name: 'shop.business',
    key: 'shop/business',
    ignore: true
  }]
}, {
  title: '团队管理',
  name: 'team',
  key: 'team',
  icon: _IconUserGroup,
  children: [{
    title: '团队列表',
    name: 'team.list',
    key: 'team/list'
  }]
}, {
  title: '拓展员',
  name: 'staff',
  key: 'staff',
  icon: _IconUserAdd,
  children: [{
    title: '拓展员列表',
    name: 'staff.list',
    key: 'staff/list'
  }]
}, {
  title: '个人中心',
  name: 'user',
  key: 'user',
  icon: _IconSettings,
  children: [{
    title: '用户设置',
    name: 'user.setting',
    key: 'user/setting'
  }, {
    title: '代理商设置',
    name: 'user.agent',
    key: 'user/agent'
  }]
}];
export const getName = (path, routes) => {
  return routes.find(item => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};
export const generatePermission = role => {
  const actions = role === 'admin' ? ['*'] : ['read'];
  const result = {};
  routes.forEach(item => {
    if (item.children) {
      item.children.forEach(child => {
        result[child.name] = actions;
      });
    }
  });
  return result;
};
const useRoute = userPermission => {
  const filterRoute = (routes, arr = []) => {
    if (!routes.length) {
      return [];
    }
    for (const route of routes) {
      const {
        requiredPermissions,
        oneOfPerm
      } = route;
      let visible = true;
      if (requiredPermissions) {
        visible = auth({
          requiredPermissions,
          oneOfPerm
        }, userPermission);
      }
      if (!visible) {
        continue;
      }
      if (route.children && route.children.length) {
        const newRoute = {
          ...route,
          children: []
        };
        filterRoute(route.children, newRoute.children);
        if (newRoute.children.length) {
          arr.push(newRoute);
        }
      } else {
        arr.push({
          ...route
        });
      }
    }
    return arr;
  };
  const [permissionRoute, setPermissionRoute] = useState(routes);
  useEffect(() => {
    const newRoutes = filterRoute(routes);
    setPermissionRoute(newRoutes);
  }, [JSON.stringify(userPermission)]);
  const defaultRoute = useMemo(() => {
    const first = permissionRoute[0];
    if (first) {
      const firstRoute = first?.children?.[0]?.key || first.key;
      return firstRoute;
    }
    return '';
  }, [permissionRoute]);
  return [permissionRoute, defaultRoute];
};
export default useRoute;